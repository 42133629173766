import React, { Component } from "react";
import { Link } from "react-router-dom";

class Menu extends Component {
  render() {
    const onButtonClick = () => {
      // using Java Script method to get PDF file
      fetch('e-katalogProtectorPrime.pdf').then(response => {
          response.blob().then(blob => {
              // Creating new object of PDF file
              const fileURL = window.URL.createObjectURL(blob);
              // Setting various property values
              let alink = document.createElement('a');
              alink.href = fileURL;
              alink.download = 'e-katalogProtectorPrime.pdf';
              alink.click();
          })
      })
  }

    return (
      <>
        <div
          className="collapse navbar-collapse show clearfix"
          id="navbarSupportedContent"
        >
          <ul className="navigation clearfix">
            <li className="">
              <Link to={"/"}>Ana Sayfa</Link>
            </li>
            <li>
              <Link to={"/hakkimizda"}>Hakkımızda</Link>
            </li>
            <li className="">
              <Link to={"/prime"}>Prime Shields</Link>
            </li>
            <li className="">
              <Link to={"/protector"}>Protector Shields</Link>
            </li>
            <li className="">
              <Link to={"/galeri"}>Galeri</Link>
            </li>
            <li className="" onClick={onButtonClick}>
             <Link to="#">E-Katalog</Link> 
            </li>
            <li>
              <Link to={"/sss"}>SSS</Link>
            </li>
            <li>
              <Link to={"/iletisim"}>İletişim</Link>
            </li>
          </ul>
        </div>
      </>
    ); 
  }
}
export default Menu;
