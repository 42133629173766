import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import Header from "../layout/header";
import Footer from "../layout/footer";

const aboutbg = require("./../../assets/images/background/image-11.jpg");
const i3 = require("./../../assets/images/background/3.png");
const i4 = require("./../../assets/images/background/4.png");
const i5 = require("./../../assets/images/background/5.png");
const i6 = require("./../../assets/images/background/6.png");
const i7 = require("./../../assets/images/background/7.png");
class Services2 extends Component {
  render() {
    return (
      <>
        <Header />

        {/* <!-- Page Banner Section --> */}
        <section className="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div className="bottom-rotten-curve alternate"></div>

          <div className="auto-container">
            <h1>Prime Shields</h1>
          </div>
        </section>
        {/* <!--End Banner Section --> */}

        {/* <!-- Services Section Five --> */}
        <section className="services-section-five">
          <div className="auto-container">
            <Tab.Container defaultActiveKey="first">
              <Nav className="nav-tabs tab-btn-style-one">
                <Nav.Item>
                  <Nav.Link eventKey="first">
                    <div className="icon-box">
                      <div className="icon">
                        <img src={i3} alt="icon" style={{height:"70px",width:"70px"}}></img>
                      </div>
                      <h5>TPU</h5>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">
                    <div className="icon-box">
                      <div className="icon">
                        <img src={i4} alt="icon" style={{height:"70px",width:"70px"}}></img>
                      </div>
                      <h5>C-TPU</h5>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">
                    <div className="icon-box">
                      <div className="icon">
                        <img src={i5} alt="icon" style={{height:"70px",width:"70px"}}></img>
                      </div>
                      <h5>TPH</h5>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fourth">
                    <div className="icon-box">
                      <div className="icon">
                        <img src={i6} alt="icon" style={{height:"70px",width:"70px"}}></img>
                      </div>
                      <h5>Cam Filmi</h5>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fifth">
                    <div className="icon-box">
                      <div className="icon">
                        <img src={i7} alt="icon" style={{height:"70px",width:"70px"}}></img>
                      </div>
                      <h5>Sunroof Koruma</h5>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="image">
                        <img
                          src={require("../../assets/images/resource/tpu.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title">
                          <div className="sub-title">TPU</div>
                          <h2>
                            Prime Shields TPU 
                          </h2>
                           <h2> Boya Koruma Filmi </h2>
                        </div>
                        <div className="text">
                          Prime Shields TPU Boya Koruma Filmi, araçların
                          boyasını korumak için tasarlanmıştır. Kendi kendini
                          yenileme ve su itici özelliği ile etkin koruma
                          sağlamaktadır. Ayrıca çakıl ve kum darbesinden veya
                          aşınmasından kaynaklanan hasarı azaltmak, boya
                          üzerinde oluşabilecek lekelenmeleri önlemek, çevresel
                          direnç ve parlaklık sunmak için üretilmiştir. Mükemmel
                          yapışma özelliğine sahip, uygulama sırasındaki
                          bozulmalara karşı dirençli, ultra şeffaf yapısı ile
                          mükemmel kullanım sağlamaktadır.
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="image">
                        <img
                          src={require("../../assets/images/resource/ctpu.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title">
                          <div className="sub-title">C-TPU</div>
                          <h2>
                            Prime Shields C-TPU
                           <h2> Boya Koruma Filmi </h2>
                          </h2>
                        </div>
                        <div className="text">
                          Prime Shields Renkli TPU Boya Koruma Filmi, araçların
                          boyasını korumak ve aynı zamanda renk değişmini
                          sağlamak için tasarlanmıştır. Kendi kendini yenileme
                          ve su itici özelliği ile etkin koruma sağlamaktadır.
                          Ayrıca çakıl ve kum darbesinden veya aşınmasından
                          kaynaklanan hasarı azaltmak, boya üzerinde
                          oluşabilecek lekelenmeleri önlemek, çevresel direnç ve
                          parlaklık sunmak için üretilmiştir. Mükemmel yapışma
                          özelliğine sahip, uygulama sırasındaki bozulmalara
                          karşı dirençli, ultra şeffaf yapısı ile mükemmel
                          kullanım sağlamaktadır.
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="third" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="image">
                        <img
                          src={require("../../assets/images/resource/tph.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title">
                          <div className="sub-title">TPH</div>
                          <h2>
                            Prime Shields TPH <br />
                            Boya Koruma Filmi
                          </h2>
                        </div>
                        <div className="text">
                          Prime Shields TPH Boya Koruma Filmi, araçların
                          boyasını korumak için tasarlanmıştır. Su itici
                          özelliği ile etkin koruma sağlamaktadır. Ayrıca çakıl
                          ve kum darbesinden veya aşınmasından kaynaklanan
                          hasarı azaltmak, boya üzerinde oluşabilecek
                          lekelenmeleri önlemek, çevresel direnç ve parlaklık
                          sunmak için üretilmiştir. Mükemmel yapışma özelliğine
                          sahip, uygulama sırasındaki bozulmalara karşı
                          dirençli, ultra şeffaf yapısı ile mükemmel kullanım
                          sağlamaktadır.
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="fourth" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="image">
                        <img
                          src={require("../../assets/images/resource/camfilmi.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title">
                          <div className="sub-title">Cam Filmi</div>
                          <h2>
                            Prime Shields <br />
                            Cam Filmi
                          </h2>
                        </div>
                        <div className="text">
                          Prime Shields Cam filmi, 2 play ve kendinden boyalı
                          olarak tasarlanmıştır. Sıcak ve ısı kesme özelliği ile
                          ön plana çıkmaktadır. Sabit renk ve şeffaf görünümü
                          ile güvenli sürüş sağlamaktadır. Herhangi bir şekilde
                          güneş yanığı olmadan serin bir yolculuk
                          yapabilirsiniz.
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="fifth" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="image">
                        <img
                          src={require("../../assets/images/resource/sunroof.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title">
                          <div className="sub-title">Sunroof Koruma</div>
                          <h2>
                            Prime Shields <br />
                            Sunroof Koruma Filmi
                          </h2>
                        </div>
                        <div className="text">
                          Prime Shields Sunroof filmi, 2 play ve kendinden
                          boyalı olarak tasarlanmıştır. Sıcak ve ısı kesme
                          özelliği ile ön plana çıkmaktadır. Sabit renk ve
                          şeffaf görünümü ile güvenli sürüş sağlamaktadır.
                          Herhangi bir şekilde güneş yanığı olmadan serin bir
                          yolculuk yapabilirsiniz.
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="sixth" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="image">
                        <img
                          src={require("../../assets/images/resource/image-18.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title">
                          <div className="sub-title">Get Rewards</div>
                          <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2>
                        </div>
                        <div className="text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum.
                        </div>
                        <div className="link-btn">
                          <Link to={"/#"} className="theme-btn btn-style-one">
                            <span className="btn-title">Learn More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </section>



        <Footer />
      </>
    );
  }
}
export default Services2;
