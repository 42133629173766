import React, { useState } from "react";

const images = [
  {
    id: 1,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/d.jpeg"),
  },
  {
    id: 2,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/b.jpeg"),
  },
  {
    id: 3,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/c.jpeg"),
  },
  {
    id: 4,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/a.jpeg"),
  },
  {
    id: 5,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/5.jpeg"),
  },
  {
    id: 6,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/6.jpeg"),
  },
  {
    id: 7,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/7.jpeg"),
  },
  {
    id: 8,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/8.jpeg"),
  },
  {
    id: 9,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/9.jpeg"),
  },
  {
    id: 10,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/10.jpeg"),
  },
  {
    id: 11,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/11.jpeg"),
  },
  {
    id: 12,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/12.jpeg"),
  },
  {
    id: 13,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/14.jpeg"),
  },
  {
    id: 14,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/15.jpeg"),
  },
  {
    id: 15,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/16.jpeg"),
  },
  {
    id: 16,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/17.jpeg"),
  },
  {
    id: 17,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/18.jpeg"),
  },
  {
    id: 18,
    name: "mountains",
    banking: true,
    text: "text",
    imgUrl: require("../../assets/images/gallery/19.jpeg"),
  },
  {
    id: 19,
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/20.jpeg"),
  },
  {
    id: 20,
    name: "mountains",
    text: "text",
    banking: true,
    imgUrl: require("../../assets/images/gallery/21.jpeg"),
  },
  {
    id: 21,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/22.jpeg"),
  },
  {
    id: 22,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/23.jpeg"),
  },
  {
    id: 23,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/1.jpeg"),
  },
  {
    id: 24,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/2.jpeg"),
  },
  {
    id: 25,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/3.jpeg"),
  },
  {
    id: 26,
    text: "text",
    name: "mountains",
    banking: true,
    imgUrl: require("../../assets/images/gallery/4.jpeg"),
  },
];

const FILTER_DEFS = {
  BANKING: (image) => image.banking,
  INSURANCE: (image) => image.insurance,
  FAMILY: (image) => image.family,
  BUSINESS: (image) => image.business,
  NONE: (image) => image,
};

const PortfolioFilter2 = (props) => {
  // eslint-disable-next-line
  const [state, setState] = useState({
    list: images,
    filterKey: "NONE",
  });

  const { list, filterKey } = state;
  const filteredList = list.filter(FILTER_DEFS[filterKey]);
  return (
    <>
      <section className="gallery-section">
        <div className="sortable-masonry">
          <div className="auto-container">
            <div className="row m-0 justify-content-md-between align-items-center">
              {/* <!--Filter--> */}
              <div
                className="filters text-center wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              ></div>
            </div>
          </div>
          <div className="auto-container">
            <div className="items-container row">
              {filteredList.map((image) => (
                <div
                  className="col-lg-4 col-md-6 gallery-block-one"
                  key={image.id}
                >
                  <div className="inner-box">
                    <div className="image">
                      <img src={image.imgUrl} alt="" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PortfolioFilter2;
