import React, { Component } from "react";
import { Link } from "react-router-dom";

import footerlogo from "../../assets/images/fl.png";
class Footer extends Component {
  render() {
    const onButtonClick = () => {
      // using Java Script method to get PDF file
      fetch("e-katalogProtectorPrime.pdf").then((response) => {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = "e-katalogProtectorPrime.pdf";
          alink.click();
        });
      });
    };
    return (
      <>
        <footer className="main-footer">
          <div className="auto-container">
            <div className="widgets-section">
              <div className="row clearfix">
                <div className="column col-lg-4">
                  <div className="footer-widget logo-widget">
                    <div className="widget-content">
                      <div className="footer-logo">
                        <Link to="/">
                          {/* <img
                            className=""
                            src={require("../../assets/images/footer-logo.png")}
                            alt=""
                          /> */}
                          {/* <h1 className="text-dark">AHAZ Group</h1> */}
                        </Link>
                      </div>
                      <div className="text">
                        Türkiye ve tüm dünyada araç koruma sektöründe öncü marka
                        !
                      </div>
                      <ul className="social-links clearfix">
                        <li>
                          <a
                            className=" contactIcon "
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                              "https://wa.me/" +
                              "+905455536450" +
                              "?text=" +
                              "Ürünlerinizle%20ilgileniyorum.%20Lütfen%20benimle%20iletişime%20geçer%20misiniz?"
                            }
                          >
                            <span className="fab fa-whatsapp"></span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.facebook.com/protectorshieldsofficial?mibextid=ZbWKwL"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="fab fa-facebook-square"></span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/primeshieldstr/?igshid=YmMyMTA2M2Y%3D"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="fab fa-instagram"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="column col-lg-8">
                  <div className="footer-widget links-widget">
                    <div className="widget-content">
                      {/* <h3>Links</h3> */}
                      <div className="row">
                        <div className="col-md-4">
                          <ul>
                            <li>
                              <Link to={"/"}>Ana Sayfa</Link>
                            </li>
                            <li>
                              <Link to={"/hakkimizda"}>Hakkımızda</Link>
                            </li>
                            <li>
                              <Link to={"/galeri"}>Galeri</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-4">
                          <ul>
                            <li>
                              <Link to={"/protector"}>Protector Shields</Link>
                            </li>
                            <li>
                              <Link to={"/prime"}>Prime Shields</Link>
                            </li>

                            <li>
                              <Link to={"/faq"}>SSS</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-4">
                          <ul>
                            <li className="" onClick={onButtonClick}>
                              <Link to="#">E-Katalog</Link>
                            </li>
                            <li>
                              <Link to={"/iletisim"}>İletişim</Link>
                            </li>
                            {/* <li><Link to={'/#'}>Case Study</Link></li>
                                                        <li><Link to={'/#'}>Terms</Link></li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="auto-container">
            <div className="footer-bottom">
              <div className="copyright">
                Copyright By
                <a
                  href="https://www.smilodonsoft.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {" "}
                  <img src={footerlogo} alt="logo"></img>{" "}
                </a>{" "}
                - 2023
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
