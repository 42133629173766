import React, { Component } from "react";
import { Link } from "react-router-dom";
import Menu from "./menu";

class Header extends Component {
  state = {
    scrolled: false,
  };

  componentDidMount() {
    var btn = document.querySelector(".mobile-nav-toggler");
    var closeBtn = document.querySelector(".close-btn");
    var body = document.getElementsByTagName("body")[0];

    function addFunc() {
      return body.classList.add("mobile-menu-visible");
    }
    function closeFunc() {
      return body.classList.remove("mobile-menu-visible");
    }

    btn.addEventListener("click", addFunc);
    closeBtn.addEventListener("click", closeFunc);

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    if (window.scrollY > 100) {
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false });
    }
  };
  sendMail({ mailSubject, mailMessage }) {
    return (window.location.href =
      "mailto:" +
      "info@ahazgroup.com" +
      "?subject=" +
      encodeURIComponent(mailSubject) +
      "&body=" +
      encodeURIComponent(mailMessage));
  }
  render() {
    const { scrolled } = this.state;
    return (
      <>
        <header
          className={scrolled ? "main-header fixed-header" : "main-header"}
        >
          <div className="header-top">
            <div className="auto-container">
              <div className="inner">
                <div className="top-left">
                  <div className="logo-box">
                    <div className="logo">
                      <Link to={"/#"}>
                        {/* <img
                          src={require("../../assets/images/logo.png")}
                          alt=""
                        /> */}
                        <h3 style={{ color: "white" }}>AHAZ Group</h3>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="top-middile">
                  {/* <div className="language">
                    <span>Language:</span>
                    <form action="#" className="language-switcher">
                      <Select options={options} />
                    </form>
                  </div> */}
                  <div className="contact-info">
                    <div className="single-info">
                      <div className="icon-box">
                        <i className="flaticon-call-1"></i>
                      </div>
                      <div className="text">Telefon</div>

                      <h4>
                        <a
                          className=" contactIcon  "
                          target="_blank"
                          rel="noopener noreferrer"
                          href="tel:+905455536450"
                        >
                          0545 553 6450
                        </a>
                      </h4>
                    </div>
                    <div className="single-info">
                      <div className="icon-box">
                        <i className="flaticon-whatsapp"></i>
                      </div>
                      <div className="text">Whatsapp</div>

                      <h4>
                        <a
                          className=" contactIcon "
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            "https://wa.me/" +
                            "+905455536450" +
                            "?text=" +
                            "Ürünlerinizle%20ilgileniyorum.%20Lütfen%20benimle%20iletişime%20geçer%20misiniz?"
                          }
                        >
                          0545 553 6450
                        </a>
                      </h4>
                    </div>
                    <div className="single-info">
                      <div className="icon-box">
                        <i className="flaticon-email-4"></i>
                      </div>
                      <div className="text">Email</div>
                      <h4>
                        <span
                          className=" contactIcon cursorPointer"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.sendMail({
                              mailSubject: "Tanışma",
                              mailMessage:
                                "Merhaba Ahaz Group, \n \n Sizinle tanışmak istiyorum. \n Lütfen benimle iletişime geçiniz. \n \n Saygılarımla, \n",
                            })
                          }
                        >
                          info@ahazgroup.com
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="top-right">
                  <Link to={"/iletisim"} className="theme-btn btn-style-two">
                    <span className="btn-title">Bize Ulaşın</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="header-upper">
            <div className="auto-container">
              <div className="inner-container">
                <div className="nav-outer clearfix">
                  <div className="mobile-nav-toggler">
                    <span className="icon fal fa-bars"></span>
                  </div>

                  <nav className="main-menu navbar-expand-md navbar-light">
                    <Menu />
                  </nav>

                  <ul className="social-links clearfix">
                    <li>
                      <a
                        className=" contactIcon "
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          "https://wa.me/" +
                          "+905455536450" +
                          "?text=" +
                          "Ürünlerinizle%20ilgileniyorum.%20Lütfen%20benimle%20iletişime%20geçer%20misiniz?"
                        }
                      >
                        <span className="fab fa-whatsapp"></span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/protectorshieldsofficial?mibextid=ZbWKwL"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="fab fa-facebook-square"></span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/primeshieldstr/?igshid=YmMyMTA2M2Y%3D"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="fab fa-instagram"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              scrolled ? "sticky-header animated slideInDown" : "sticky-header"
            }
          >
            <div className="auto-container clearfix">
              <div className="logo pull-left">
                <Link to="/">
                  {" "}
                  <h3 style={{ color: "black" }}>AHAZ Group</h3>
                </Link>
                {/* <Link to={"/#"} title="">
                  <img
                    src={require("../../assets/images/sticky-logo.png")}
                    alt=""
                    title=""
                  />
                </Link> */}
              </div>

              <div className="pull-right">
                <nav className="main-menu clearfix">
                  <Menu />
                </nav>
              </div>
            </div>
          </div>

          <div className="mobile-menu">
            <div className="menu-backdrop"></div>
            <div className="close-btn">
              <span className="icon flaticon-cancel"></span>
            </div>

            <nav className="menu-box">
              <div className="nav-logo">
                <Link to="/">
                  <h3 style={{ color: "white" }}>AHAZ Group</h3>
                </Link>
                {/* <Link to={"/#"}>
                  <img src="assets/images/logo.png" alt="" title="" />
                </Link> */}
              </div>
              <div className="menu-outer">
                <Menu />
              </div>
              <div className="social-links">
                <ul className="clearfix">
                  <li>
                    <a
                      className=" contactIcon "
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        "https://wa.me/" +
                        "+905455536450" +
                        "?text=" +
                        "Ürünlerinizle%20ilgileniyorum.%20Lütfen%20benimle%20iletişime%20geçer%20misiniz?"
                      }
                    >
                      <span className="fab fa-whatsapp"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/protectorshieldsofficial?mibextid=ZbWKwL"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="fab fa-facebook-square"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/primeshieldstr/?igshid=YmMyMTA2M2Y%3D"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="fab fa-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>
      </>
    );
  }
}
export default Header;
