import React, { Component } from "react";
import { Nav, Tab } from "react-bootstrap";
import Header from "../layout/header";
import Footer from "../layout/footer";

const aboutbg = require("./../../assets/images/background/image-11.jpg");
const i1 = require("./../../assets/images/background/1.png");
const i2 = require("./../../assets/images/background/2.png");

class Services2 extends Component {
  render() {
    return (
      <>
        <Header />

        {/* <!-- Page Banner Section --> */}
        <section class="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div class="bottom-rotten-curve alternate"></div>

          <div class="auto-container">
            <h1>Protector Shields</h1>
            {/* <ul class="bread-crumb clearfix">
                            <li><Link to={'/#'}>Home</Link></li>
                            <li class="active">Services</li>
                        </ul> */}
          </div>
        </section>
        {/* <!--End Banner Section --> */}

        {/* <!-- Services Section Five --> */}
        <section class="services-section-five">
          <div class="auto-container">
            <Tab.Container defaultActiveKey="first">
              <Nav className="nav-tabs tab-btn-style-one2">
                <Nav.Item>
                  <Nav.Link eventKey="first">
                    <div class="icon-box">
                      <div class="icon">
                        {/* <span class="flaticon-team"></span> */}
                        <img src={i1} alt="icon" style={{height:"70px",width:"70px"}}></img>
                      </div>
                      <h5>TPU</h5>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">
                    <div class="icon-box">
                      <div class="icon">
                        {/* <span class="flaticon-money"></span> */}
                        <img src={i2} alt="icon" style={{height:"70px",width:"70px"}}></img>
                      </div>
                      <h5>Cam Filmi</h5>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first" className=" fadeInUp animated">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="image">
                        <img
                          src={require("../../assets/images/resource/ptpu.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content pl-lg-4">
                        <div class="sec-title">
                          <div class="sub-title">TPU</div>
                          <h2>
                            Protector Shields <br />
                            Boya Koruma Filmi
                          </h2>
                        </div>
                        <div class="text">
                          Protector Shields TPU Boya Koruma Filmi, araçların
                          boyasını korumak için tasarlanmıştır. Kendi kendini
                          yenileme ve su itici özelliği ile etkin koruma
                          sağlamaktadır. Ayrıca çakıl ve kum darbesinden veya
                          aşınmasından kaynaklanan hasarı azaltmak, boya
                          üzerinde oluşabilecek lekelenmeleri önlemek, çevresel
                          direnç ve parlaklık sunmak için üretilmiştir. Mükemmel
                          yapışma özelliğine sahip, uygulama sırasındaki
                          bozulmalara karşı dirençli, ultra şeffaf yapısı ile
                          mükemmel kullanım sağlamaktadır.
                        </div>
                        {/* <div class="link-btn">
                          <Link to={"/#"} class="theme-btn btn-style-one">
                            <span class="btn-title">Learn More</span>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second" className=" fadeInUp animated">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="image">
                        <img
                          src={require("../../assets/images/resource/pcamfilmi.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content pl-lg-4">
                        <div class="sec-title">
                          <div class="sub-title">Cam Filmi</div>
                          <h2>
                            Protector Shields <br />
                            Cam Filmi
                          </h2>
                        </div>
                        <div class="text">
                          Protector Shields Cam filmi, 2 play ve kendinden boyalı
                          olarak tasarlanmıştır. Sıcak ve ısı kesme özelliği ile
                          ön plana çıkmaktadır. Sabit renk ve şeffaf görünümü
                          ile güvenli sürüş sağlamaktadır. Herhangi bir şekilde
                          güneş yanığı olmadan serin bir yolculuk
                          yapabilirsiniz.
                        </div>
                        {/* <div class="link-btn">
                          <Link to={"/#"} class="theme-btn btn-style-one">
                            <span class="btn-title">Learn More</span>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </section>

        {/* <!-- Gallery Section --> */}
        {/* <ServiceFilter1/> */}

        {/* <!-- Sponsors Section --> */}


        <Footer />
      </>
    );
  }
}
export default Services2;
